.features {
    .sub-header {
        max-width: rem(920);
        margin: 0 auto;
        text-align: center;
        margin-bottom: rem(30);

        @include media(lg) {
            margin-bottom: rem(75);
        }
    }

    .tab__icon {
        min-height: rem(55);
    }

    .swipe-tabs {
        @extend .border-bottom-light;
    }
    .swipe-tab {
        padding-bottom: 2px;

        @include mediaMax(sm) {
            width: 100% !important;
            text-align: center !important;
            margin-bottom: rem(30);
        }

        &__inner {
            padding-bottom: rem(15);
            cursor: pointer;
        }

        &.active-tab {
            .swipe-tab__inner {
                @extend %box-shadow-primary;
            }
        }
    }

    &--tab__image {
        position: relative;
        max-width: rem(500);

        @include mediaMax(lg) {
            margin: 0 auto;
        }

        &:after {
            content: '';
            @include pos-abs(auto, 15px, 10px, auto);
            background: url(../img/triangle.svg) no-repeat center center;
            height: rem(40);
            width: rem(40);
            z-index: -1;
        }

    }

    .main-container {
        position: relative;

        &:before {
            content: '';
            @include pos-abs(-20px, auto, auto, -30px);
            background: url(../img/dots-pattern-1.svg) no-repeat top $left;
            height: rem(160);
            width: rem(160);
            z-index: -1;
        }

    }

    .swipe-tab-content {
        position: relative;

        @include mediaMax(lg) {
            text-align: center;
        }


    }

    .tab-content {
        max-width: rem(490);
        background: $color-white;

        #{$theme-dark} & {
            background: $midnight;
        }

        @include mediaMax(lg) {
            margin: 0 auto;
            text-align: center;
        }

        &__list {
            @extend .border-bottom-light;
            padding-bottom: rem(25);
            margin-bottom: rem(25);

            li {
                position: relative;

                &::before {
                    @include pos-abs (10px, auto, auto, 0);
                    content: '';
                    height: rem(12);
                    width: rem(12);
                    border-radius: 100px;
                    background-color: $light-blue-grey;
                }

                &:not(:last-child) {
                    margin-bottom: rem(25);
                }

                p {
                    margin-#{$left}: rem(30);
                    text-align: $left;
                }
            }
        }
    }
}

/**--------- Feature Slider 2 ---------**/
.features-custom-container {
    @include mediaMax(lg) {
        padding-bottom: $standard-space /2;
        padding-top: $standard-space /2;
    }

    @include media(xl) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: $standard-space;
    }

    @include media(lg) {
        @include mediaMax(xl) {
            padding-bottom: $standard-space;
        }
    }

    .content-wrapper {
        @include media(xl) {
            max-width: 770px;
            margin-#{$left}: auto;
            padding-#{$right}: 15px;
        }

        @include customMedia(1921) {
            max-width: 820px;
        }

        @include customMedia(1921) {
            max-width: 820px;
        }
    }

    .device-image {
        @include mediaMax(xl) {
            margin-bottom: rem(10);

            img {
                max-height: rem(500);
            }
        }

        @include mediaMax(lg) {
            img {
                max-height: rem(450);
            }
        }
    }

}

.features--slider-2 {
    position: relative;

    @include media(xl) {
        overflow: hidden;
        width: calc(41.66667% - 40px);

    }

    .slider-tabs {
        text-align: $left;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        @include media(lg) {
            width: 950px;

        }

        @include media(xl) {
            width: 950px;

        }

        @include customMedia(1600) {
            max-width: 950px;

        }

        .slider-tab {
            background: transparent;
            font-size: $font-size-21;
            font-weight: 700;
            color: rgba($color-white, .2);
            transition: .3s;
        }

        .slick-current.slick-active {
            .slider-tab {
                color: $color-white;

            }
        }
    }

    .slider-contents {
        max-width: rem(400);
    }

    // arrow
    .slick-arrow {
        background: rgba(255, 255, 255, 0.2);
        height: rem(50);
        width: rem(50);
        border-radius: 100px;
        transition: .3s;
        position: absolute;
        top: rem(-120);

        @include media(md) {
            top: rem(-150);

        }

        i {
            line-height: rem(50);
            transition: .3s;
        }

        &:hover {
            background: $color-white;
            box-shadow: 0 2px 34px rgba(0, 0, 0, 0.21);

            i {
                @extend %color-primary;
            }

        }

        &.slick-prev {
            $left: 0;
            @include rtl{
                right: rem(60);

            }
        }

        &.slick-next {
            left: rem(60);
            @include rtl{
                right: rem(0);

            }
        }

    }

}
