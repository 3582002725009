.blog-main {
    .widget {
        .article__thumbnail {
            flex: 0 0 55px;
        }

        p {
            line-height: 1.6;
        }
    }

    figure {
        margin-bottom: rem(35);
        margin-top: rem(35);

        img {
            border-radius: rem(5);
            margin-bottom: rem(10);
        }
    }

    ul,
    ol,
    li {
        padding: inherit;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        display: list-item;
    }
}

.blog-details {
    .content {
        p {
            margin-bottom: rem(20);
        }

        h5 {
            margin-bottom: rem(25);
            margin-top: rem(25);
        }

    }
}