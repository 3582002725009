
// SCSS Import
// =============================================================
@import "typography.scss";
@import "colors";
@import "boxed-layout";

// Init
// =============================================================
%bg-color-primary {
	background: $color-primary;
}
%fill-primary{
	fill: $color-primary;
}
%color-primary {
	color: $color-primary;
}
%box-shadow-primary {
	box-shadow: 0px 2px 0px $color-primary;
}
.border-primary {
	border-color: $color-primary;
}
%fill-primary {
	fill: $color-primary;
}

/*! Place your custom styles here/ below. 
    This will make it much easier to update the template later - you'll only need to preserve "5-user" folder
===================================================================================================*/
